import React, { useState } from 'react';
import './DogFoodCalculator.css';

const DogFoodCalculator: React.FC = () => {
  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  const [sterilized, setSterilized] = useState('');
  const [walks, setWalks] = useState('');
  const [brand, setBrand] = useState('');
  const [activeMode, setActiveMode] = useState('');
  const [result, setResult] = useState<number | null>(null);

  const handleCalculate = () => {
    const weightNum = parseFloat(weight);
    const caloriesPer100g = parseFloat(brand);

    if (isNaN(weightNum) || isNaN(caloriesPer100g)) {
      alert('Please enter valid numbers for weight and dog food brand.');
      return;
    }

    // 计算 RER (Resting Energy Requirement)
    const rer = weightNum * 30 + 70;

    // 根据不同情况设置 DER (Daily Energy Requirement) 的参考系数
    let factors: number[] = [];

    if (age === 'puppy') {
      factors.push(2.5); // 平均值
    } else if (age === 'nopuppy') {
      factors.push(1.8); // 健康成年犬参考系数
    }

    if (sterilized === 'yes') {
      factors.push(1.6); // 绝育成年犬参考系数
    } else if (sterilized === 'no') {
      factors.push(2); // 绝育成年犬参考系数
    }

    if (walks === 'nowalking') {
      factors.push(1.3); // 不爱动的狗狗参考系数
    } else if (walks === 'ordianary') {
      factors.push(1.8); // 正常运动
    } else if (walks === 'active') {
      factors.push(3.3); // 活跃的狗狗参考系数
    }

    if (activeMode === 'Active') {
      factors.push(2.5); // 活跃的狗狗
    } else if (activeMode === 'noActive') {
      factors.push(1.3); // 不爱动的狗狗
    }

    // 计算参考系数的平均值
    const factor = factors.reduce((acc, val) => acc + val, 0) / factors.length;

    const der = rer * factor;
    const dailyFoodGrams = (der / caloriesPer100g) * 100;

    setResult(dailyFoodGrams);
  };

  return (
    <div className="container">
      <div className="header">
        <h1>
          <span>Precisely Calculate</span>
          <span>How Much Your Dog Should Eat Each Day</span>
        </h1>
      </div>
      <div className="main">
        <div className="left-section">
          <h2>
            <span>Please Enter the Necessary </span>
            <span>Pet Information</span>
          </h2>
          <div className="form-group">
            <div>
              <label>
                AGE年齡
                <select value={age} onChange={(e) => setAge(e.target.value)}>
                  <option value="">Select</option>
                  <option value="puppy">Younger than 1 year old</option>
                  <option value="nopuppy">1 year old or older</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Weight體重 (KG)
                <input type="text" value={weight} onChange={(e) => setWeight(e.target.value)} />
              </label>
            </div>
            <div>
              <label>
                Has it been sterilized?是否絕育
                <select value={sterilized} onChange={(e) => setSterilized(e.target.value)}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                How many times the dog walk each day?每天外出幾次
                <select value={walks} onChange={(e) => setWalks(e.target.value)}>
                  <option value="">Select</option>
                  <option value="nowalking">Dog stays at home all the time</option>
                  <option value="ordianary">1-2 times each day</option>
                  <option value="active">3 or more times each day</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Choose the Dogfood Brand you feed your dog你當前在用的狗糧品牌
                <select value={brand} onChange={(e) => setBrand(e.target.value)}>
                  <option value="">Select</option>
                  <option value="347.5">ACANA爱肯拿</option>
                  <option value="357">Royal Canin</option>
                  <option value="380">Purina Pro Plan</option>
                  <option value="350">Hill's Science Diet</option>
                  <option value="365">Blue Buffalo</option>
                  <option value="389">Taste of the Wild</option>
                  <option value="435">Orijen</option>
                  <option value="360">Wellness CORE</option>
                  <option value="335">Nutro</option>
                  <option value="328">Iams</option>
                  <option value="365">Diamond Naturals</option>
                  <option value="270">Freshpet</option>
                  <option value="375">Canidae</option>
                  <option value="370">Merrick</option>
                  <option value="330">Eukanuba</option>
                  <option value="400">Nature’s Variety Instinct</option>
                  <option value="350">American Journey</option>
                  <option value="365">Victor</option>
                  <option value="335">Rachael Ray Nutrish</option>
                  <option value="300">The Farmer’s Dog</option>
                  <option value="320">Spot and Tango</option>
                  <option value="380">NatureBridge比瑞吉</option>
                  <option value="345">Wanpy顽皮</option>
                  <option value="365">Pure & Natural天然派</option>
                  <option value="320">Pedigree宝路</option>
                  <option value="340">Care凯锐思</option>
                  <option value="350">Myfoodie麦富迪</option>
                  <option value="325">ChongChong宠宠</option>
                  <option value="356">Navarch耐威克</option>
                  <option value="345">Bridge PetCare百利</option>
                  <option value="358">伯纳天纯</option>
                  <option value="412">网易严选</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Active Mode活動類型
                <select value={activeMode} onChange={(e) => setActiveMode(e.target.value)}>
                  <option value="">Select</option>
                  <option value="Active">Most Time Running/Jumping</option>
                  <option value="noActive">Most Time Laying Down</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="form-group">
            <button onClick={handleCalculate}>Calculate 計算</button>
          </div>
          {result !== null && (
            <div className="result">
              <h3>Your dog needs to eat approximately {result.toFixed(2)}g of dog food per day.</h3>
              <h3>您的狗狗需要每天吃大約 {result.toFixed(2)}g的狗糧喔～</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DogFoodCalculator;

import React from 'react';
import DogFoodCalculator from './components/DogFoodCalculator';
import './index.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <DogFoodCalculator />
    </div>
  );
};

export default App;
